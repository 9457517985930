@import 'Styles/Base/includes';

/**
*
* PageHomeSchool
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.PageHomeSchool {
    $root: &;

    &__Search {
        padding: 30px 0 38px;

        @include media(md) {
            padding: 48px 0 56px;
        }
    }

    &__SearchContainer {
        @include u-wrap(content);
    }

    &__SearchTitle {
        margin-bottom: 16px;

        @include media(sm) {
            margin-bottom: 24px;
        }
    }
}
